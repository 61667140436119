@import 'src/static-assets/styles/abstracts/variables';

.statusContent {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}

.currentStatusContainer,
.updatedStatusContainer {
    h3 {
        margin-top: 30px;
        font-size: $b1-font-size;
        display: flex;
        justify-content: center;
        align-self: center;
        font-weight: 300;
        letter-spacing: 1.2px;
    }
}

.currentStatusContainer {
    h3 {
        color: $accent-blue;
    }
}

.updatedStatusContainer {
    h3 {
        color: $orange;
    }
}
