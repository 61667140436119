.lbaContainer {
    margin-left: 4rem;
}

.lbaTitle {
    text-align: center;
    margin-bottom: 1rem;
}

.contentContainers {
    display: flex;
    flex-direction: column;
    min-width:  330px;
    max-width: 100%;
}


.lbaLogoInfoContainer {
    display: flex;
}

.bankInfo {
    margin-left: 18px;
    margin-top: 5px;
    padding-bottom: 7px;
}

.informationBlock {
    margin-top: 1rem;
}