@import 'src/static-assets/styles/abstracts/variables';
@import 'src/static-assets/styles/abstracts/mixins';

.login {
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    min-width: 800px;
    max-width: 80%;
    min-height: 500px;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.input {
    @include input-field;
}
::placeholder {
    @include placeholder-input;
}

.link {
    color: $primary-white;
    text-decoration: none;
}

.logoContainer {
    margin-bottom: 2rem;
    width: 100%;
    height: auto;
    text-align: center;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.button {
    background-color: $accent-blue;
    color: white;
    border-radius: 25px;
    width: 150px;
    height: 40px;
    border: none;
    font-weight: 100;
    box-shadow: 0px 4px 20px 0px#c3cbcd;
    cursor: pointer;
}
.button:hover {
    transition: ease-in-out 0.3s;
    background-color: $dark-bg-green;
}
