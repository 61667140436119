@import 'src/static-assets/styles/abstracts/_variables.scss';

.mainWrapper {
    display: flex;
    justify-content: center;
}

.twoColDiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.leftSideSpacer {
    width: 75%;
}

.whyteFont,
.largerWhyteFont {
    font-family: $font-stack-whyte;
    font-size: $b4-font-size;
    overflow: hidden;
}

.largerWhyteFont {
    font-size: $b2-font-size;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    min-width: 250px;
}

.contentData {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.detailsDiv,
.titleDiv {
    display: flex;
}

.bigText {
    font-size: $h3-font-size;
}

.dollarSign {
    color: $accent-green;
}

.detailsDiv {
    margin-bottom: 100px;
}

.detailsTextDiv {
    width: 225px;
}

.currencyDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: -70px;
}

.dbaName {
    color: $accent-blue;
}

.leftDiv {
    width: 35%;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.rightDiv {
    display: flex;
    width: 65%;
    justify-content: space-between;
}

.greyTextDiv {
    width: 35%;
}

.helperText {
    display: block;
    color: $primary-black;
    font-size: $b3-font-size;
}

.noMutualContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 1.5rem;
}

.mutualContainer {
    display: flex;
    justify-content: space-between;
}

.payeePayorContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 750px;
    margin: 2rem 0 2rem 0;
}

.payeePayorWrapper {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.statusMainComponent {
    margin-top: 1rem;
    overflow: hidden;
}

.payeeContainer,
.payorContainer {
    display: flex;
    flex-direction: column;
}

.payorContainer {
    margin-left: 4rem;
}

.informationBlock {
    margin-top: 1rem;
}

.row {
    display: flex;
    margin-top: $small-font-size;
}

.greyText {
    font-size: $b5-font-size;
    color: $dark-grey-text;
    font-family: $font-stack-whyte;
    overflow: hidden;
}

.payeeTitle,
.payorTitle {
    text-align: center;
    margin-bottom: 1rem;
}

.historyLink {
    margin-left: 1rem;
    background: none;
    border: none;
    color: $accent-blue;
    font-size: $b1-font-size;
    cursor: pointer;
}

.container {
    display: flex;
}

.textAreaContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 100%;
}

.labelContainer {
    margin-right: 1.5rem;
}

.actionRow {
    display: flex;
    width: 100%;
}

.mutualContainer:last-child {
    margin-right: 40px;
}

.transferContainer {
    display: flex;
    gap: 13px;
    margin-top: -27px;
    font-size: $b4-font-size;
    font-family: $font-stack-whyte;
}

.transferContainer p {
    overflow: hidden;
}

.currencyValue {
    font-size: $h3-font-size;
}


.astertikContainer {
    margin-top: -20px;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: $b5-font-size;
    display: flex;
    justify-content: right;
    padding-right: 0.2rem;
}