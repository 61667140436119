@import 'src/static-assets/styles/abstracts/variables';

.sectionHalf {
    width: 50%;
}

.contentContainer {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 10px);
}

.noPendingContentContainer {
    justify-content: flex-end;
}

.moduleActionsContainer {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.noLeftMarginContainer,
.normalContainer {
    width: 100%;
}

.normalContainer {
    margin-left: 1rem;
    width: calc(100% - 1rem);
}

.hr {
    height: 0;
    border: 0.5px solid $primary-light-gray;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.documentsContainer {
    display: flex;
    width: 65px;
    justify-content: left;
}

.noPendingDocumentsContainer {
    width: 70%
}

.pendingContainer {
    width: auto;
}

.darkerText {
    font-weight: bolder;
    font-size: 14px;
}

.smallerText {
    font-size: 12px;
    font-weight: 100;
    text-decoration: none;
    font-family: $font-stack-whyte;
    overflow: visible;
    width: 82px;
    margin-top: 3px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 110px;
}

.documentText {
    width: 125px;
}

.documentsTextContainer {
    width: 175px;
}

.docStatusContainer {
    width: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
}

.zendeskContainer {
    display: flex;
    justify-content: space-between;
    min-height: 20px;
}

.zendeskLink,
.accountLink {
    color: $accent-blue;
}

.linkContainer {
    margin-left: 15px;
}

.toolTipContainer {
    width: 100%;
    color: $placeholder-text-color;
    margin-left: 30px;
    font-size: $b3-font-size
}

.pendingContent {
    display: flex;
}

.moduleTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inputsContainer {
    width: auto;
    display: flex;
}

.input {
    margin-right: 5px;
}

.accountLink {
    font-size: $b3-font-size;
    text-decoration: none;
}

.inputContainer {
    display: flex;
    justify-content: center;
    width: auto;
    margin-left: 15px;
}

.dormantText {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dormantInputContainer {
    margin-left: 5px;
}