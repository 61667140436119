@import 'react-toastify/dist/ReactToastify.css';
@import 'src/static-assets/styles/abstracts/variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

html {
    font-family: $font-stack-rebound;
    color: $primary-black;
    height: 100vh;
}

#root {
    height: 100vh;
}

body {
    height: 100vh;
}

.content {
    height: 100%;
}

// data grid styles
.dx-searchbox .dx-icon-search {
    overflow: hidden;
}

.dx-datagrid-headers {
    margin-top: 2rem;
    overflow: hidden;
    color: $primary-black;
    font-weight: 400;
}

#mini .dx-datagrid-headers {
    margin-top: 10px;
}

.dx-datagrid-rowsview .dx-row {
    border-bottom: 1.5rem solid transparent;
}

#mini .dx-datagrid-rowsview .dx-row {
    border-bottom: 0px solid transparent;
}

#mini .dx-datagrid-header-panel {
    margin-bottom: 0;
}

.dx-datagrid-rowsview .dx-row:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}

.dx-datagrid-header-panel {
    border: none;
    margin-bottom: 1rem;
}

.dx-datagrid .dx-column-indicators .dx-sort-index-icon {
    display: none;
}

// pop up styles
.dx-overlay-shader {
    background-color: rgba(73, 73, 73, 0.3);
}

// toast styles
.Toastify__toast--success {
    background-color: $light-bg-green;
    color: $primary-black;
    font-size: 18px;
}

.Toastify__toast--error {
    background-color: $red;
    color: $primary-white;
    font-size: 18px;
}

.Toastify__close-button>svg {
    display: none !important;
}

.Toastify__toast-container--top-center {
    margin-top: 2rem;
    width: 1000px;
    width: 70%;
}

.Toastify__toast-body {
    text-align: center;
}

.Toastify__toast-icon {
    display: none;
}

// tooltip styles
.__react_component_tooltip.show {
    overflow: hidden;
}

// header filter styles 
.datagrid {
    overflow: visible;
}

.datagrid-table {
    position: static;
}

.dx-overlay-wrapper {
    height: 500px;
    overflow: visible;
}

.dx-list-item-before-bag.dx-list-select-checkbox-container {
    width: 32px !important;
}

.dx-list-item {
    border: none;
}

.dx-datagrid .dx-header-filter-empty {
    overflow: hidden;
}

.dx-popup-content {
    padding: 0 20px 20px 20px;
}

.dx-header-filter {
    overflow: hidden;
}

#sso {
    height: 320px;
}