@import 'src/static-assets/styles/abstracts/variables';

.sectionContainers {
    margin-top: 1rem;
    display: flex;
}

.title {
    font-weight: bold;
    border-bottom: 1px $component-border-color solid;
    padding-bottom: 0.5rem;
}

.fieldLabel {
    width: 50%;
    color: $dark-grey-text;
}

.response {
    width: 50%;
    color: $dark-grey-text;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    width: 50%;
    display: flex;
}

.statusContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
}

.flexContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.flexItems,
.riskScoreItem,
.urlItem,
.paymentStatus {
    display: flex;
    align-items: left;
    margin-bottom: 1rem;
}

.userUploadDocs {
    display: flex;
    align-items: left;
    align-items: center;
    margin-bottom: 1rem;
}

.riskScoreItem,
.urlItem,
.paymentStatus {
    margin-top: 1rem;
}

.link {
    text-decoration: none;
    color: $accent-blue;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.accountText {
    color: $dark-grey-text;
}