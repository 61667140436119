@import 'src/static-assets/styles/abstracts/variables';

.balanceContainer {
    width: 35%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.balanceContainer:not(:last-child) {
    border-right: 1px $vertical-hr-color solid;
}

.content {
    width: 100%;
    border: solid 1px $component-border-color;
    border-radius: 10px;
    height: 100%;
    margin: 1rem 0;
    padding: 1rem 1.5rem;
    transition-duration: 500ms;
}

.hrLine {
    height: 0;
    border: 1px solid $primary-light-gray;
    margin-bottom: 7px;
    margin-top: 10px;
}

.limitsTitle {
    font-size: $b1-font-size;
}

.tooltipContainer {
    display: inline-flex;
    cursor: pointer;
    margin-left: 5px;
}

.infoContainer {
    font-size: $explanation-font-size;
    width: 250px;
    height: 165px;
}

.currencyValue {
    font-size: 1.5rem;
}