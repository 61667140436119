@import 'src/static-assets/styles/abstracts/variables';

.container {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 1rem;
    align-items: center;
}

.navigation {
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: $primary-black;
    margin: 0 5px;
}

.navigation:last-of-type {
    margin-right: 35px;
}

.linksContainer {
    display: flex;
}

.active {
    color: $accent-blue;
}