@import 'src/static-assets/styles/abstracts/variables';

.container {
    display: flex;
    margin-bottom: 8px;
}

.row {
    display: flex;
}

.resultsText {
    margin-bottom: 15px;
    position: absolute;
    z-index: 1000;
}

.accountingLinksRow {
    margin-top: 15px;
}

.link {
    text-decoration: none;
}

.active {
    border-bottom: 1px solid $primary-black;
}

.accountingLink {
    color: $primary-black;
    padding-bottom: 15px;
}

.spacerLink {
    margin-left: 15px;
}

.downloadLink {
    margin: 1px 0 0 24px;
    font-size: 14px;
}

.contentContainer {
    display: flex;
    margin-top: 15px;
    overflow: visible;
}

.grid {
    max-height: 465px;
}

.transactionsContainer {
    width: 70%;
    height: 525px;
    border-radius: 5px;
    border: 1px solid $light-bg-gray;
}

.contentWrapper {
    height: calc(100% - 20px);
    margin: 0 20px;
    padding-top: 15px;
    overflow: hidden;
    position: relative;
}

.pagesWrapper {
    height: 20px;
    justify-content: flex-end;
}

.pagesRow {
    display: flex;
    justify-content: flex-end;
}

.greyText {
    color: $grey-text-color;
}