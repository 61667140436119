@import 'src/static-assets/styles/abstracts/_variables.scss';

.mainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.payeeLbaContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.payeeLbaWrapper {
    display: flex;
    max-width: 100%;
    justify-content: center;
}

.payeeLBA {
    margin-right: 10px;
    width: 100%;
}

.payorLBA {
    margin-left: 10px;
    width: 100%;
}

.lbaContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.payeePayorWrapper {
    width: 750px;
    display: flex;
    margin-top: 2em;
}