@import 'src/static-assets/styles/abstracts/variables';

.moduleTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}

.lbaContainer {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 3rem;
}

.lbaWrapper {
    width: 50%;
}