.paramsForActionDropDown {
    width: 295px;
}

.form {
    margin-top: 1em;
    display: block;
}

.formLabel {
    margin-right: 1em;
}

.submitContainer {
    margin-top: 1em;
    width: 300px;
    text-align: right;
}