@import 'src/static-assets/styles/abstracts/variables';

.splitPane {
    display: flex;
    justify-content: center;
}

.linkedAccountContainer,
.accountStatusContainer {
    display: flex;
    flex-direction: column;
}

.accountText {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    min-height: 20px;
}

.linkedAccountContainer {
    width: 60%;
    padding-left: 25px;
}

.accountStatusContainer {
    width: 40%;
    padding-right: 25px;
}

.leftAlignText {
    text-align: left;
    width: 100%;
}

.rightAlignText {
    text-align: right;
    width: 100%;
}

.row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3px;
}

.statusContainerWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.statusContainer {
    padding: 5px;
    border: 1px solid $light-bg-gray;
    border-radius: 5px;
    font-size: 9px;
    width: 50px;
    height: 23px;
    margin-left: 5px;
    font-family: $font-stack-euclid;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.statusContainerHigh {
    background-color: $accent-red;
}

.statusContainerLow {
    background-color: $accent-green;
}

.secondaryText {
    font-size: 10px;
    color: $dark-grey-text;
    overflow: visible;
}

.labelText {
    width: 90px;
}