@import 'src/static-assets/styles/abstracts/mixins';
@import 'src/static-assets/styles/abstracts/variables';

.dollarInput {
    height: 35px;
    padding: 10px;
    @include input-field;
}

.input {
    @include input-field;
}

::placeholder {
    @include placeholder-input;
}

.inputDynamic,
.containerDynamic {
    width: 100%;
}

.textInputContainer {
    overflow: hidden;
    position: relative;
    display: inline;
}