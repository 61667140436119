@import 'src/static-assets/styles/abstracts/variables';

.button {
    width: 150px;
    height: 40px;
    border-radius: 25px;
    background-color: $accent-blue;
    color: $primary-white;
    border: none;
    cursor: pointer;
}

.button:hover {
    transition: ease-in-out 0.3s;
    background-color: $light-bg-blue;
}

.button:active {
    background-color: $dark-bg-blue;
}

.button:disabled {
    background-color: $diasbled-color;
    cursor: not-allowed;
}

.button.alert {
    background-color: $accent-red;

    &:hover {
        background-color: $red-link;
    }
}