@import 'src/static-assets/styles/abstracts/variables';

.accountWrapperContainer {
    display: flex;
    padding-bottom: 10px;
}

.accountDataContainer {
    width: 38%;
}

.accountText {
    color: $dark-grey-text;
}

.blockUnblockContainer {
    width: 38%;
    margin-right: 7px;
}

.backupContainer {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
}