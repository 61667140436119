@import 'src/static-assets/styles/abstracts/variables';

.GreyLabel {
    padding: 5px;
    border: 1px solid $light-bg-gray;
    border-radius: 5px;
    font-size: 9px;
    height: 23px;
    font-family: $font-stack-euclid;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-color: $light-bg-gray;
}