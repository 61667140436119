@import 'src/static-assets/styles/abstracts/variables';

.radioLabelContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.radioLabel {
    background-color: $red; // default to not being checked
}

.isGreen {
    background-color: $accent-green;
}

.radioContainer {
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px;
}

.radio {
    height: 100%;
}