@import 'src/static-assets/styles/abstracts/variables';
@import 'src/static-assets/styles/abstracts/mixins';

.menuItem {
    width: 100%;
    border-radius: 4px;
    list-style: none;
    display: flex;
    overflow: hidden;
}

.container {
    height: 36px;
    overflow: hidden;
    width: 100%;
}

.link {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    text-decoration: none;
    color: $primary-black;
    height: 35px;
    overflow: hidden;
    font-family: $font-stack-whyte;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
}
