@import 'src/static-assets/styles/abstracts/variables';

.plan-type {
    color: $primary-black;
    padding: 4px 12px 4px 12px;
    border-radius: 5px;
    font-size: 12px;
}

.basic {
    background-color: $light-purple-color;
}

.freemium {
    background-color: $light-grey;
}

.premium-monthly {
    background-color: $light-grey;
}

.premium-annually {
    background-color: $dark-grey;
}