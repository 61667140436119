@import 'src/static-assets/styles/abstracts/variables';


.pendingNumber {
    border: none;
    background: none;
    cursor: pointer;
}

.pendingNumber:focus {
    color: $accent-blue;
}

.linkButton {
    color: $primary-white;
    text-decoration: none;
    width: 120px;
    padding: 0px;
    margin: 0px;
    height: 45px;
}

.linkButton:hover {
    background-color: inherit;
}

.isActive {
    color: $accent-blue;
}

.label {
    margin-left: 5px;
}