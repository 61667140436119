@import 'src/static-assets/styles/abstracts/variables';

.scoreContainer,
.lowScoreContainer {
    position: absolute;
    bottom: 12px;
    left: 70px;
    font-size: 10px;
    width: 42px;
    height: 18px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.lowScoreContainer {
    background-color: $accent-green;
}
.highScoreContainer {
    background-color: $red;
}

.isAccountDetailsScore,
.isAccountDetailsLowScore {
    position: unset;
    font-size: $small-font-size;
    width: 42px;
    height: 18px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.isAccountDetailsLowScore {
    background-color: $accent-green;
}

.notSetScoreContainer {
    background-color: $light-bg-gray;
}