@import 'src/static-assets/styles/abstracts/variables';

.contentWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 2rem 0;
}

.menubar {
    max-width: 240px;
}

.engDashboard {
    flex-grow: 1;
    margin-left: 1rem;
}

.row {
    display: flex;
}

.bigGap {
    padding: $section-padding-size 0;
}

.link {
    text-decoration: none;
}

.title {
    margin-bottom: 10px;
    margin-left: 1rem;
}

.hr {
    margin-left: 1rem;
}

.wrapper {
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;
}

.exceptionsContainer {
    display: flex;
    width: 100%;
    flex-grow: 1 1 1 1;
    gap: 1rem;
    flex-wrap: wrap;
}
