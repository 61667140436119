@import 'src/static-assets/styles/abstracts/variables';

.isCancel {
    border-color: $blue-link;
    border-style: solid;
    color: $accent-blue;
    background-color: $primary-white;
    margin-right: 10px;
}

.isCancel:hover {
    background-color: $light-bg-babyblue;
    transition: ease-in-out 0.3s;
}

.isCancel:active {
    border-color: $dark-bg-blue;
    color: $dark-bg-blue;
}