@import 'src/static-assets/styles/abstracts/variables';

.Dropdown {
    align-items: center;
    display: flex;
}

.label {
    flex: 2;
    font-size: $b2-font-size;
}

.select {
    flex: 8;
    padding: 10px;
    border-radius: 4px;
    appearance: none;
    border-color: $input-border-color;
}

.hasNotChanged {
    color: $placeholder-text-color;
}