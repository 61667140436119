@import 'src/static-assets/styles/abstracts/mixins';
@import 'src/static-assets/styles/abstracts/variables';

.numberInput {
    @include input-field;

    &::placeholder {
        @include placeholder-input;
    }
}
