@import 'src/static-assets/styles/abstracts/variables';

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 1rem 0;
    border-bottom: 1px solid black;
    align-items: center;
}

.logoContainer {
    display: flex;
    height: 100%;
    margin: auto 0;
    align-items: center;
    align-content: center;
}
