@import 'src/static-assets/styles/abstracts/variables';
@import 'src/static-assets/styles/abstracts/mixins';

.container {
    @include home-card;
    background-image: linear-gradient(to bottom, $accent-green, $accent-blue);
    color: $primary-white;
    text-decoration: none;
}

.container:hover {
    @include card-hover;
}

.link {
    @include link-style;
}
