@import 'src/static-assets/styles/abstracts/variables';

.contentContainer,
.contentWrapper {
    min-width: 100px;
    max-width: 230px;
    height: 125px;
    border: 1px solid $light-blue;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    flex-grow: 1;
}

.contentContainer:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 4px rgb(0 0 0 / 0.2);
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
}

.containerHeader {
    margin-top: 10px;
}

.totalContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.totalText {
    margin-left: 15px;
}

.totalNum,
.totalsNum {
    margin-right: 30px;
    color: $red;
    font-size: 25px;
}

.totalsNum {
    color: $accent-blue;
}

.link {
    text-decoration: none;
    color: $primary-black;
}