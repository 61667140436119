@import 'src/static-assets/styles/abstracts/_variables.scss';

.headerContainer {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
}

.headerContainer h4 {
    font-weight: bold;
}

.linksContainer {
    width: 95%;
    display: flex;
   justify-content: flex-end;
}

.link {
    margin-right: 20px;
    margin-left: 1rem;
    background: none;
    border: none;
    color: $accent-blue;
    font-size: $b3-font-size;
    cursor: pointer;
    text-decoration: none;
}

.pdfLink {
    display: none;
}

.invoicesTitleContainer {
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
    gap: 2.5rem;
    padding-right: 40px;
    overflow: hidden;
}

.titleWrapper {
    overflow: hidden;
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.titleWrapper:first-child {
    margin-left: 40px;
}

.wrapper {
    max-height: 160px;
    height: 100%;
}

.invoiceItemsContainer {
    display: flex;
    width: 100%;
    margin-left: 40px;
    padding-right: 40px;
}

.itemsWrapper {
    justify-content: space-between;
    display: flex;
    width: 50%;
    padding-right: 40px;
    max-width: 100%;
    margin-top: 17px;
}

.qtyContainer {
    min-width: 60px;
}

.item {
   padding-bottom: 25px;
   overflow: hidden;
}

.totalContainer {
   width: 50%;
   margin-left: 50%;
   padding-left: 20px;
   margin-top: 1rem;
 
}

.itemWrapper {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
}

.itemWrapper:last-child {
    margin-top: 15px;
}

.qtyTitle {
    padding-right: 35px;
}
