@import 'src/static-assets/styles/abstracts/mixins';
@import 'src/static-assets/styles/abstracts/variables';

.input {
    @include input-field;
}

::placeholder {
    @include placeholder-input;
}
