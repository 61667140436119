@import 'src/static-assets/styles/abstracts/_variables.scss';

.row {
    display: flex;
    margin-top: $small-font-size;
}

.greyText {
    font-size: $b5-font-size;
    color: $dark-grey-text;
    font-family: $font-stack-whyte;
    overflow: hidden;
}

.leftSideSpacer {
    width: 75%;
}