@import 'src/static-assets/styles/abstracts/variables';

.title {
    color: $primary-black;
    margin-bottom: 5px;
}

.container {
    margin-top: 0.8rem;
    width: 100%;
}