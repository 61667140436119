.textarea {
    width: 600px;
    height: 130px;
    max-width: 100%;
    border: 1px solid #d7e1fa;
    border-radius: 5px;
    padding: 0.8rem;
    color: black;
    margin-top: 8px;
}

.textarea::placeholder,
.wordCount {
    color: #c1c4d5;
}

.wordCount {
    float: right;
    font-size: 14px;
}

.isFullSize {
    width: 100%;
}

.divFullSize {
    width: 100%;
}