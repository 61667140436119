@font-face {
    font-family: 'ES Rebound Grotesque';
    src: url(../../fonts/ESRebondGrotesque-Regular.woff) format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'ABC Whyte Book';
    src: url(../../fonts/ABCWhyte-Book.woff) format('woff');
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url(../../fonts/EuclidCircularA-Regular.woff2);
}
