@import 'src/static-assets/styles/abstracts/variables';

.infoContent {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 1rem;
}

.amountContainer {
    width: 40%;
    text-align: center;
}

.accountText {
    text-align: center;
}

.amountTitle {
    margin-bottom: 25px;
    text-align: center;
}

.accountLinkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
   width: 75%;
    max-width: 100%;
    border: 1px solid $primary-light-gray;
    border-radius: 5px;
    padding: 7px 10px 10px 10px;
    margin-top: 0.5rem;
}

.btnContainer {
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.reasoningContainer {
    display: flex;
    flex-direction: column;
}

.userInput {
    color: $dark-grey-text;
    margin-top: 0.5rem;
}

.logoContainer {
    margin-right: 20px;
    width: 20%;
    overflow: hidden;
}

.hopscotchORA {
    margin-top: 10px;
}

.accountDetailsContainer {
    margin-top: 10px;
    display: flex;
    width: 320px;
    max-width: 100%;
    border: 1px solid $primary-light-gray;
    border-radius: 5px;
    padding: 12px 10px 7px 10px;
    overflow: hidden;
}
