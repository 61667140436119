@import '../abstracts/variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.componentsContainer {
    padding: 1.5rem;
    background-color: $primary-light-gray;
    color: $primary-white;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    font-size: 25px;
    letter-spacing: 1px;
}

.link {
    text-decoration: none;
}

@media (max-width: 730px) {
    .components-container {
        flex-direction: column;
        font-size: 20px;
    }
}
