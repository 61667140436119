@import 'src/static-assets/styles/abstracts/variables';

.react-toggle-track {
    width: 50px;
    height: 24px;
    background-color: $light-blue;
}
.react-toggle--checked .react-toggle-track {
    background-color: $orange;
}

.react-toggle--checked .react-toggle-thumb--checked {
    background-color: $orange;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $orange;
}

.react-toggle:hover:not(.react-toggle--checked) .react-toggle-track {
    background-color: $light-blue;
}

.react-toggle-thumb {
    width: 22px;
    height: 22px;
    border-color: $light-bg-purple;
    background-color: $light-bg-purple;
    outline: none;
}

.react-toggle--focus {
    outline: none;
}
.react-toggle--checked .react-toggle-thumb {
    border-color: $orange;
    background-color: $dark-orange;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px transparent;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
}
