/* ========================================= */
/*               Mixins                      */
/* ========================================= */

/* styles for business and engineering cards on home page */
@mixin home-card {
    max-width: 100%;
    width: 450px;
    height: 380px;
    border-radius: 10px;
    box-shadow: 0px 0px 100px #666;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin card-hover {
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
}

/* styles for a link */
@mixin link-style {
    text-decoration: none;
    color: $primary-white;
    cursor: pointer;
}

/* styles for input fields */
@mixin input-field {
    height: 32px;
    max-width: 100%;
    width: 269px;
    border: 1.8px solid $input-border-color;
    border-radius: 5px;
    font-size: 13px;
    padding: 8px;
    margin-bottom: 1.2rem;
}

@mixin placeholder-input {
    font-family: $font-stack-euclid;
    color: $placeholder-text-color;
}