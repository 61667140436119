@import 'src/static-assets/styles/abstracts/variables';

.title {
    font-weight: bold;
    padding-bottom: 0.5rem;
}

.limitText {
    text-align: right;
    margin-top: -1rem;
    color: $light-blue;
    z-index: 100;
}

.hideButtonContainer {
    display: none;
    transition-duration: 3s;
    z-index: 0;
}

.titleContainer {
    display: flex;
    margin-top: 1rem;
    height: 26px;
}

.historyLink {
    margin-left: 1.5rem;
    background: none;
    border: none;
    color: $accent-blue;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 0.5rem;
}

.statusContainer {
    display: flex;
    justify-content: space-evenly;
    max-width: 100%;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.btnContainer {
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.frozen {
    font-size: $h4-font-size;
    color: $red;
}

.parentContainer {
    display: flex;
}

.accountActionsContainer {
    width: 50%;
    margin-right: 15px;
}

.ownershipContainer {
    width: 50%;
    margin-left: 15px;
}

.linkedAccountWrapper {
    max-height: 365px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.firstRow {
    margin-top: 1rem;
}

.labelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 248px;
}

.label {
    width: 248px;
    font-size: $b2-font-size;
}

.dollarLabel {
    margin-bottom: 17px;
}

.reasoningLabel {
    width: 150px;
    font-size: $b2-font-size;
}

.toggleContainer {
    width: 25%;
}

.greyTextContainer {
    width: 95%;
    margin-bottom: 1rem;
}

.greyText {
    font-size: 12px;
    color: $grey-text-color;
    margin-top: 10px;
}

.inputDynamic,
.containerDynamic {
    width: 100%;
    margin: 0;
    margin-left: 8px;
}

.accountText {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    min-height: 20px;
}

.accountContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.accountLinkContainer {
    width: 75%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid $primary-light-gray;
}

.rightContainer {
    margin-left: 15px;
}