@import 'src/static-assets/styles/abstracts/variables';

.content {
    width: calc(30% - 15px);
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible;
}

.balanceContainer {
    height: 100px;
    width: 100%;
    border-radius: 5px;
    margin-left: 15px;
    padding: 0 20px;
    box-shadow: 0px 4px 20px #C3CBCD;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
}

.balanceText {
    margin-bottom: 5px;
}