@import 'src/static-assets/styles/abstracts/variables';


.file-upload-form {
    padding-top: 20px;
}

.form-error {
    margin: 0;
    color: $accent-red;
    font-size: 12px;
}

.form-file-upload {
    border-color: rgb(216, 220, 222) !important;
    padding: 1rem 0.714286rem !important;
    color: $light-grey-color !important;
    margin-bottom: 5px !important;
    margin-top: 15px !important;
    border-width: 0.142857rem !important;
    border-radius: 0.357143rem !important;
    background-color: rgb(255, 255, 255) !important;
    opacity: 0.9 !important;
}

.file-list-item {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;

    >div {
        border: 1px solid $component-border-color;
        border-radius: 4px;
        color: $light-grey-color;
        height: 56px;
        padding: 15px 20px;
        background-color: #fafafa;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.mr-2 {
    margin-right: 5px;
}