@import 'src/static-assets/styles/abstracts/variables';

.link {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
}

.link:focus {
    color: $accent-blue;
}

.isActive {
    color: $accent-blue;
}

.businessName {
    color: $primary-black;
}