@import 'src/static-assets/styles/abstracts/variables';

.headerContainer {
    width: 100%;
}

.firstTitle {
    color: $primary-black;
}

.secondTitle {
    color: #8388ab;
    margin-bottom: 15px;
}