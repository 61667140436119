@import 'src/static-assets/styles/abstracts/variables';

.section {
    border: solid 1px $component-border-color;
    max-width: 100%;
    width: 100%;
    margin: 0 0 $grid-size $grid-size;
    border-radius: 10px;
    display: flex;
    justify-content: baseline;
    align-items: left;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: $section-padding-size;
    height: fit-content;
    position: relative;
}
