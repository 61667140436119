@import 'src/static-assets/styles/abstracts/variables';

.infoContent {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 1rem;
}

.userContainer {
    border: 1px solid $component-border-color;
    border-radius: 5px;
    width: 260px;
    height: 70px;
    padding: 14px;
    position: relative;
    margin-top: 1rem;
    overflow: hidden;
}

.amountContainer {
    width: 20%;
    text-align: center;
}

.userName {
    font-size: $b5-font-size;
    margin-left: 21px;
}

.userInfoContainer {
    align-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: absolute;
    top: 8px;
    left: 50px;
}

.accountText {
    text-align: center;
}

.secondTitle {
    margin-bottom: 35px;
    text-align: center;
}

.dateContainer {
    font-size: $small-font-size;
    position: absolute;
    bottom: 15px;
    right: 50px;
    color: $dark-grey-text;
}

.accountContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.accountLinkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $primary-light-gray;
    border-radius: 5px;
    padding: 7px 10px 10px 10px;
}