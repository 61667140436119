@import 'src/static-assets/styles/abstracts/variables';

.wrapper {
    display: flex;
    margin-left: 5px;
    color: $primary-black;
}

.typeSpan {
    width: 22px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    z-index: 1000;
    background-color: $dark-grey-text;
    float: left;
    position: relative;
    left: 12px;
    top: 2px;
}

.labelContainer {
    font-size: $explanation-font-size;
    width: 95px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unblockLabel {
    background-color: $accent-green;
}

.blockLabel {
    background-color: $red;
}