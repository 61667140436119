@import 'src/static-assets/styles/abstracts/variables';

.card {
    border: solid 1px $component-border-color;
    width: 100%;
    height: 125px;
    margin: 0 0 $grid-size 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: left;
    text-align: left;
    flex-direction: column;
    padding: $section-padding-size;
}

.card:active {
    border: solid 1px $accent-blue;
}

.cardActive {
    border: solid 1px $accent-blue;
}

.cardContentWrapper {
    display: flex;
    flex-direction: column;
}

.companyNameLinkContainer {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.name {
    font-weight: 400;
    overflow: hidden;
    transition-duration: 500ms;
}

.link {
    text-decoration: none;
    margin-bottom: 2px;
}

.linkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.emailContainer {
    overflow: hidden;

    p {
        color: $grey-text-color;
        overflow: none;
        height: 30px;
    }
}

@media screen and (max-width: 1250px) {
    .customerCard {
        padding: 2rem 1rem;
        transition-duration: 500ms;
    }
}