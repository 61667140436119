@import 'src/static-assets/styles/abstracts/_variables.scss';

.linkContainer {
    text-decoration: none;
    color: $primary-black;
}

.bizDetailsContainer {
    display: flex;
    transform: scale(0.985);
    transition: 0.2s;
    max-width: 100%;
}

.bizDetailsContainer:hover {
    transform: scale(1);
    transition: 0.2s;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50px;
    height: 100%;
    margin-right: $b3-font-size;
    margin-top: 6px;
}

.logo {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: $primary-light-gray;
}

.infoContainer {
    width: 80%;
}

.infoFirstLayer,
.infoSecondLayer {
    display: flex;
    justify-content: space-between;
}

.businessNameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: $b3-font-size;
    width: 240px;
}

.businessName {
    color: $primary-black;
}

.verificationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -28px;
    min-width: 58px;
    overflow: visible;
}

.statusContainer {
    display: flex;
}

.statusContainer {
    display: flex;
    flex-direction: column;
    background-color: $light-bg-gray;
    width: 75px;
    min-height: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
}

.high {
    background-color: $red;
}

.low {
    background-color: $accent-green;
}

.statusText {
    font-size: $b5-font-size;
    font-family: $font-stack-whyte;
    overflow: hidden;
    text-decoration: none;
    color: $primary-black;
}

.sinceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 99px;
    overflow: visible;
}

.greyText {
    font-size: $b5-font-size;
    color: $dark-grey-text;
    font-family: $font-stack-whyte;
    overflow: visible;
    text-align: right;
    width: 92px;
}

.smallGreyText {
    font-size: $small-font-size;
}

.noZoom {
    transform: scale(1);
}

.noZoom:hover {
    transform: scale(1);
}