@import 'src/static-assets/styles/abstracts/variables';

.statusContainer {
    padding: 5px;
    border: 1px solid $light-bg-gray;
    border-radius: 5px;
    font-size: 9px;
    width: 100px;
    height: 23px;
    margin-left: 5px;
    font-family: $font-stack-euclid;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.statusContainerHigh {
    background-color: $red;
}

.statusContainerLow {
    background-color: $accent-green;
}

.active {
    border: 1.5px solid $accent-blue;
    overflow: hidden;
}