.factoredRiskModelContainer {

    h3,
    hr {
        margin-bottom: 10px;
    }

    .allow-business-container {
        display: flex;
        gap: 10px;

        >button {
            height: 30px !important;
            width: 75px !important;
        }
    }
}