.contentWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 2rem 0;
}

.menubarDiv {
    min-width: 240px;
}
