@import 'src/static-assets/styles/abstracts/variables';

.businessName, .componentLink  {
    color: $primary-black;
}

.componentLink {
    cursor: pointer;
    text-decoration: none;
}

.isActive {
    color: $accent-blue;
}