/* ========================================= */
/*            Variables                      */
/* ========================================= */

// primary colors
$primary-yellow: #fffc38;
$primary-black: #1d191f;
$primary-dark-gray: #333333;
$primary-white: #ffffff;
$primary-light-gray: #e7e6e5;

// accent colors
$accent-blue: #005fda;
$accent-pink: #ff9fe0;
$accent-green: #00c39e;
$accent-orange: #ffc3aa;
$accent-red: #da0e00;
$accent-light-gray: #c4c4c4;

// light background
$light-bg-gray: #e7e5e4;
$light-bg-orange: #f1cebe;
$light-bg-purple: #c8d4f2;
$light-bg-pink: #facee7;
$light-bg-green: #c3ead8;
$light-bg-yellow: #f1efd4;
$light-bg-blue: #4382ff;
$light-bg-babyblue: #f2f7fd;
$light-bg-red: #c3171799;

// dark background
$dark-bg-navy: #011539;
$dark-bg-maroon: #4a033b;
$dark-bg-green: #003629;
$dark-bg-blue: #001ccc;
$dark-bg-gray: #C3CBCD;
$dark-bg-amber: #BE7402;

// misc colors
$light-purple-color: #F1E6FF;
$light-grey: #EAF1FB;
$dark-grey: #C0CCDD;
$component-border-color: #dbdbdb;
$input-border-color: #d6e0fa;
$placeholder-text-color: #c1c4d5;
$grey-text-color: #8388ab;
$light-grey-color: #979797;
$red: #eb5757;
$orange: #ffbe5b;
$dark-orange: #ff9b05;
$blue-link: #3166ef;
$red-link: #f41000;
$verified-status-bg-color: #e0ffea;
$verified-status-color: #5abb7a;
$light-blue: #d7e1fa;
$diasbled-color: #d3f0fb;
$dark-grey-text: #919191;
$vertical-hr-color: #CDCDCE;
$light-red: #ffdbdb; // can we swap this with an existing color?

// typography
$font-stack-rebound: 'ES Rebound Grotesque';
$font-stack-whyte: 'ABC Whyte Book';
$font-stack-euclid: 'Euclid Circular A';
$h0-font-size: 100px;
$h1-font-size: 62px;
$h2-font-size: 45px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$b0-font-size: 20px;
$b1-font-size: 18px;
$b2-font-size: 16px;
$b3-font-size: 15px;
$b4-font-size: 14px;
$b5-font-size: 12px;
$small-font-size: 10px;
$explanation-font-size: 13px;

// Grid sizes
$padding-top-size: 0.5rem;
$grid-size: 1rem;
$section-padding-size: 2rem;