@import 'src/static-assets/styles/abstracts/variables';

.isAmber {
    color: $orange !important;
}

.isDarkAmber {
    color: $dark-bg-amber !important;
}

.isRed {
    color: $red;
}

.isGreen {
    color: $accent-green;
}