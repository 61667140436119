@import 'src/static-assets/styles/abstracts/variables';

.accountText {
    text-align: center;
    font-weight: 800;
}

.accountLinkContainer {
    display: flex;
    width: 75%;
    max-width: 100%;
    border: 1px solid $primary-light-gray;
    border-radius: 5px;
    padding: 7px 10px 10px 10px;
    margin-top: 0.5rem;
    margin-left: 65px;
}

.statusContainer {
    margin-top: 1rem;
    justify-content: center;
    display: flex;
    gap: 100px;
    margin-bottom: 1rem;

}
.statusHeader {
    font-weight: 800;
}

.pendingStatus {
    color: $orange;
    margin-left: 20px;
    font-size: $b2-font-size;
    font-weight: 400;
    padding-top: 1rem;
}

.cancelledStatus {
    color: $red;
    margin-left: 20px;
    font-size: $b2-font-size;
    font-weight: 400;
}