@import 'src/static-assets/styles/abstracts/variables';

.componentLink {
    cursor: pointer;
    text-decoration: none;
    color: $primary-black;
    margin-right: 5px;
}

.isActive {
    color: $accent-blue;
}

.pendingNumber {
    border: none;
    background: none;
    cursor: pointer;
}

.pendingNumber:focus {
    color: $accent-blue;
}

.isActive {
    color: $accent-blue;
}

.label {
    margin: 0 4.5px 0 0;
}