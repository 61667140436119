@import 'src/static-assets/styles/abstracts/variables';
@import 'src/static-assets/styles/abstracts/mixins';

.menuContainer {
    width: 240px;
    border-radius: 10px;
    background-color: $primary-white;
    border: 1px solid $component-border-color;
}

.list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 1rem;
}

.link {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $primary-black;
    height: 35px;
    overflow: hidden;
    font-family: $font-stack-whyte;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 5px;
}

.menuItem {
    width: 100%;
    border-radius: 4px;
    list-style: none;
    display: flex;
    padding-left: 1rem;
    overflow: hidden;
}

.list svg {
    margin-right: 1rem;
}

.svgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    height: 36px;
    overflow: hidden;
    width: 100%;
    display: flex;
}