@import 'src/static-assets/styles/abstracts/variables';

.card {
    height: 100px;
    border: solid 1px $component-border-color;
    margin: 0 0 $grid-size 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 1rem 1.5rem;
    transition-duration: 500ms;
}

.cardContent {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.statusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 30px;
    max-width: 30px;
    height: 100%;
    overflow: hidden;
}

.textContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    overflow: hidden;
}

.moduleText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    width: 100%;
    margin-left: 1rem;
    transition-duration: 500ms;
}

.moduleCode {
    margin-left: 1rem;
    font-size: $b5-font-size;
    color: $grey-text-color;
    width: 95%;
    text-align: left;
}

.toggleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    min-width: 100px;
    margin-right: -50px;
}

@media screen and (max-width: 1250px) {
    .moduleText {
        font-size: $b4-font-size;
        margin-left: 1rem;
        transition-duration: 500ms;
    }
}