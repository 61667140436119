@import 'src/static-assets/styles/abstracts/variables';

.tagContainer {
    width: 22px;
    height: 20px;
    border-radius: 100%;
    margin-left: -12px;
    margin-top: 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: $explanation-font-size;
}

.typeSpan {
    background-color: grey;
    color: black;
    width: 22px;
    height: 20px;
    border-radius: 100%;
    margin-left: -12px;
    margin-top: 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: $explanation-font-size;
}

.opsLabel {
    background-color: $dark-bg-gray;
}

.hopLabel {
    background-color: $orange;
}