@import 'src/static-assets/styles/abstracts/variables';

.overallActionsContainer {
    margin-top: 2rem;
}

.riskProfileScoreContainer,
.riskProfilePassContainer {
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 0 1.5rem;
}

.riskProfileScoreText,
.riskProfilePassText {
    flex-grow: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.reasoningText {
    margin-bottom: 6px;
}

.reasoningContainer {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 1.5rem;
}

.lastButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-top: 25px;
    padding: 0 1.5rem;
}

.hr {
    height: 0;
    border: 0.5px solid $primary-light-gray;
}

.sectionText {
    margin-bottom: 1rem;
}