@import 'src/static-assets/styles/abstracts/variables';

.container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

#gridContainer {
}

.tracebtn {
    margin-left: 1rem;
    width: 50px;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: $accent-blue;
    color: $primary-white;
    cursor: pointer;
    font-size: 14px;
}

.tracebtn:hover {
    transition: ease-in-out 0.5s;
    background-color: $dark-bg-green;
}
