@import 'src/static-assets/styles/abstracts/variables';

.headerText {
    margin-top: 1rem;
}

.infoContent {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 1rem;
    width: 100%;
}

.accountContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.accountText {
    text-align: center;
}

.accountLinkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $primary-light-gray;
    border-radius: 5px;
    padding: 7px 10px 10px 10px;
}

.infoContainer {
    width: 89%;
    display: flex;
    justify-content: space-between;
}

.secondTitle {
    margin-bottom: 35px;
    text-align: center;
}

.statusWrapper {
    display: flex;
    justify-content: center;
}

.statusContainer {
    display: flex;
    justify-content: space-evenly;
    background-color: $primary-light-gray;
    font-size: $b5-font-size;
    flex-direction: column;
    width: 75px;
    height: 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
}

.statusContainerHigh {
    background-color: $red;
}

.statusContainerLow {
    background-color: $accent-green;
}