@import 'src/static-assets/styles/abstracts/variables';

.percentText {
    height: 20px;
    width: 40px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    justify-content: center;
    background-color: $light-bg-gray;
    border-radius: 5px;
    color: $primary-black;
}

.percentage {
    text-align: -webkit-center;
    text-align: center;
}

.green {
    background-color: $accent-green;
}

.warning {
    background-color: $primary-yellow;
}

.danger {
    background-color: $red;
}