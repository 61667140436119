@import 'src/static-assets/styles/abstracts/_variables.scss';

.center {
    margin: auto;
    width: 50%;
    max-width: 400px;
    min-width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
}

div.loader {
    font-size: $h0-font-size;
}

.loaded {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}