.payeeContainer {
    margin-left: 4rem;
}

.payeeTitle {
    text-align: center;
    margin-bottom: 1rem;
}

.informationBlock {
    margin-top: 1rem;
}