@import 'src/static-assets/styles/abstracts/variables';

.reasonHeader {
    margin-top: 30px;
}

.btnContainer {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.userInput {
    color: $dark-grey-text;
}
