@import 'src/static-assets/styles/abstracts/variables';

.componentLink {
    cursor: pointer;
    text-decoration: none;
    color: $primary-black;
}

.isActive {
    color: $accent-blue;
}

.userData {
    color: $primary-black;
}

.isRiskStatusHigh {
    background-color: $red;
}

.isRiskStatusLow {
    background-color: $accent-green;
}

.isRiskStatusNotSet {
    background-color: $light-bg-gray;
}

.scoreContainer {
    width: 100%;
    height: 25px;
    border-radius: 5px;
    color: $primary-black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $b5-font-size;
}

.secondaryHeader {
    color: $grey-text-color;
}