@import 'src/static-assets/styles/abstracts/variables';

.reasoningContainer {
    display: flex;
    flex-direction: column;
}

.userInput {
    color: $dark-grey-text;
    margin-top: 0.5rem;
}