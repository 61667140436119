@import 'src/static-assets/styles/abstracts/variables';

.gridContent {
    margin-bottom: 5px;
}

.reviewStatusDiv {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: lightgray;
    cursor: pointer;
}

.failed {
    background-color: red;
}

.passed {
    background-color: $verified-status-color;
}

.caseLink {
    text-decoration: none;
    color: $primary-black;
}

.businessName {
    color: $primary-black;
}