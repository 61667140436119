@import 'src/static-assets/styles/abstracts/variables';

.invoiceDetailsContainer {
    display: flex;
    align-content: space-between;
    flex-flow: column wrap;
    gap: 25px;
    width: 50%;
}

.invoiceDetailsContainer div {
    align-items: baseline;
    align-content: space-between;
    display: flex;
    width: 100%;
}

.subHeaderContainer {
    display: flex;
    justify-content: space-between;

    span:first-child {
        font-size: 14px;
        margin-bottom: 10px;
    }

    span:last-child {
        font-size: $b0-font-size;
    }
}

.iconContainer {
    width: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    border: 1px solid $component-border-color;
    border-radius: 10px;
}

.dateStatusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px;
}

.userInformationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        display: block;
        margin-bottom: 10px;
    }
}

.userInformationName {
    display: flex;
    align-items: center;

    img {
        margin-right: 5px;
    }
}

.actionRow {
    display: flex;
    width: 100%;
}

.actionsHistoryContainer {
    display: flex;
}

.actionContainer {
    height: 100px;
    width: 100%;
    margin-right: 25px;
}

.markedPaidActionContainer {
    height: 100px;
    width: 48%;
}

.titleToggleContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.hrlineContainer {
    margin: 1rem 0;
}

.historyLink {
    margin-left: 1.5rem;
    background: none;
    border: none;
    color: $accent-blue;
    font-size: $b2-font-size;
    cursor: pointer;
    margin-top: 8px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.smallGreyText {
    font-size: $b5-font-size;
    color: $dark-grey-text;
    margin-top: 5px;
}

.astertikContainer {
    margin-top: -20px;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: $b5-font-size;
    display: flex;
    justify-content: right;
    padding-right: 0.2rem;
}

.textAreaContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 100%;
}

.labelContainer {
    margin-right: 1.5rem;
}