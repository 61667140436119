@import 'src/static-assets/styles/abstracts/variables';

.header {
    display: flex;
    justify-content: space-between;
}

.titleContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.iconContainer {
    margin-right: 1rem;
}

.pageHeaderText {
    font-size: $b1-font-size;
    font-weight: 700;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.accountStatusVerified {
    margin-left: 1rem;
    padding: 5px 25px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $verified-status-bg-color;
    color: $verified-status-color;
}

.hr {
    height: 0;
    border: 0.5px solid $primary-light-gray;
    margin-top: 20px;
}

.wrapper {
    width: 100%;
    display: flex;
}

.verificationStatus {
    width: 400px;
    text-align: center;
    font-size: $b1-font-size;
}


.headerTextContainer {
    width: 100%;
    position: relative;
    display: flex;
}

.planContainer {
    width: 30%;
}