@import 'src/static-assets/styles/abstracts/_variables.scss';

.linkedBankAccount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $component-border-color;
    border-radius: 5px;
    width: 100%;
    height: 80px;
}

.institutionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
}

.row {
    display: flex;
    margin: 4px 15px 0 15px;
    flex: 7;
}

.leftAside {
    display: flex;
    align-items: center;
}

.firstRow {
    display: flex;
    justify-content: space-between;
}

.secondRow {
    display: flex;
    justify-content: space-between;
    margin: 7px 20px 0 15px;
}

.ownerName {
    font-size: .7rem;
}

.smallerText {
    font-size: 12px;
}