@import 'src/static-assets/styles/abstracts/mixins';
@import 'src/static-assets/styles/abstracts/variables';

.title {
    background-image: linear-gradient($accent-blue, $accent-green);
    background-clip: text;
    color: transparent;
    font-size: 35px;
    margin: 0;
    overflow: hidden;
}

.titleDiv {
    height: 55px;
    margin-top: 2rem;
    overflow: hidden;
}
