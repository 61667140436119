@import 'src/static-assets/styles/abstracts/variables';

.title {
    font-size: $b4-font-size;
    margin-left: 10px;
}

.info {
    color: $blue-link;
}

.danger {
    color: $red;
}

.success {
    color: $accent-green;
}

.warning {
    color: $orange;
}

.titleContainer {
    margin: 25px 0 10px 0;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
}

.titleDiv {
    display: flex;
}