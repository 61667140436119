@import 'src/static-assets/styles/abstracts/variables';

.statusContainer {
    padding: 5px;
    border: 1px solid $light-bg-gray;
    border-radius: 5px;
    font-size: 9px;
    width: 100px;
    height: 23px;
    margin-left: 5px;
    font-family: $font-stack-euclid;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.radioLabel {
    max-height: 100%;
    height: 30px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 16px;
}