@import 'src/static-assets/styles/abstracts/variables';

.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.downloadReportLink {
    margin-top: 15px;
    color: $accent-blue;
    background: none;
    border: none;
    cursor: pointer;
}
.popupTitle {
    font-size: 15px;
    font-weight: bolder;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: $font-stack-euclid;
}
.links {
    display: flex;
    cursor: pointer;
}
.btnContainer {
    position: absolute;
    right: 0;
    bottom: 5px;
}
