@import 'src/static-assets/styles/abstracts/variables';

.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.container {
    background-color: #fafafa;
    border-radius: 10px;
    width: 100%;
    height: 80px;
    padding: 1rem;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.container {
    &:hover {
        .title {
            color: $accent-blue;
        }
    }
}

.title {
    color: #030d45;
    margin-bottom: 5px;
}

.generateTitle {
    font-size: 16px;
}

.titleContainer {
    margin: 30px 0 10px 0;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
}

.titleDiv {
    display: flex;
    justify-content: space-between;
    gap: 13.5rem;
}