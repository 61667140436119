@import 'src/static-assets/styles/abstracts/variables';

.balanceContainer {
    width: calc(30% - 15px);
    height: 150px;
    border-radius: 5px;
    margin-left: 15px;
    padding: 0 20px;
    box-shadow: 0px 4px 20px #C3CBCD;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.smallRow {
    height: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.currencyWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30%;
}

.smallGreyText {
    font-size: 12px;
    color: $dark-grey-text;
}