@import 'src/static-assets/styles/abstracts/variables';

.amlHeaderContainer {
    display: flex;
    margin-top: 1rem;
}

.amlCardContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.amlHeader {
    width: 48%;
    padding-left: 10px;
}

.kycHeader {
    margin-left: 1.5rem;
}

.amlText {
    font-weight: 400;
    overflow: hidden;
    transition-duration: 500ms;
}

.amlCard {
    width: 50%;
}

.kycCardContainer {
    margin-left: 1rem;
}