@import 'src/static-assets/styles/abstracts/_variables.scss';

.actionContainer {
    height: 100px;
    width: 50%;
    max-width: 100%;
    margin-right: 25px;
}

.titleToggleContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.smallGreyText {
    font-size: $b5-font-size;
    color: $dark-grey-text;
    margin-top: 5px;
}
