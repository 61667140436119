@import 'src/static-assets/styles/abstracts/variables';

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
}

.button,
.isPlanShown {
    font-size: $b1-font-size;
    background: none;
    background-color: none;
    border: none;
    cursor: pointer;
    padding-bottom: 8px;
    color: $light-grey-color;
}

.isPlanShown {
    color: $primary-black;
    border-bottom: 1px $primary-black solid;
    font-weight: bold;
}

.featuresList {
    display: block;
    font-size: $b2-font-size;
    margin-bottom: 12px;
    margin-top: 12px;
}

.checkBox {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gap {
    gap: 20px;
}

.sectionWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.featureItemWrapper {
    width: 50%;
    padding: 0px 8px;
}

.titleClassName {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    color: $primary-black;
    margin-bottom: 16px;
}

.subtextClassName {
    color: $primary-black;
    font-size: 14px;
    font-weight: 400;
}