@import 'src/static-assets/styles/abstracts/variables';

.header {
    display: flex;
    flex-direction: row;
    margin: 16px 0px;
    gap: 19px;
}

.link {
    text-decoration: none;
    color: $blue-link;
    margin-left: 20px;
}

.flexContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.flexItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;

    .itemField {
        width: 50%;
        color: $primary-black;
    }
}

.rightBlockContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.buttonsGroup {
    display: flex;
    flex-direction: row;
    gap: 19px;
    margin-top: 24px;

    .saveButton {
        border-radius: 20px;
    }
}

.history-link {
    background: none;
    border: none;
    color: $accent-blue;
    font-size: 14px;
    cursor: pointer;
}