@import 'src/static-assets/styles/abstracts/variables';

// @TODO: Still need to re-evaluate. Might be able to skinny down some of the styles.
.linkedAccountBlock {
    height: 170px;
    border: 1px solid $light-blue;
    border-radius: 5px;
    margin: 10px 10px 0 10px;
    padding: 10px;
    overflow: hidden;
}

.highlightBorder {
    border: 3px;
    border-color: $accent-blue;
    border-radius: 5px;
    border-style: solid;
}

.row,
.ownerDetailsRow,
.ownerDetailsDoubleRow,
.accountDetailsRow {
    display: flex;
    justify-content: space-between;
    overflow: visible;
}


.ownerDetailsRow {
    height: 22px;
    text-align: center;
    align-content: center;
    align-items: center;
}

.ownerDetailsDoubleRow {
    height: 28px;
}

.accountDetailsRow {
    margin-bottom: 5px;
}

.secondRow {
    margin-top: 7px;
}

.headerWrapper {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
}


.label {
    width: 100%;
    font-size: 14px;
}

.institutionName {
    font-size: 16px;
    white-space: nowrap;

    @media (max-width: 1475px) {
        font-size: 14px;
    }
}

.bigLabel {
    font-size: 16px;
}

.smallLabel {
    font-size: 12px;
}

.secondaryLabel {
    width: 100px;
}

.smallerSecondaryLabel {
    width: 75px;
}

.RadioLabelDynamic {
    @media (max-width:1500px) {
        width: 70px;
    }

    @media (max-width:1278px) {
        width: 50px;
    }
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
}

.rightContainer {
    display: flex;
    flex: 7;
}

.buttonWrapper {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    overflow: hidden;
}

.rightContainer {
    margin-left: 15px;
}

.statusContainer {
    padding: 5px;
    border: 1px solid $light-bg-gray;
    border-radius: 5px;
    font-size: 9px;
    width: calc(100% - 5px);
    height: 23px;
    font-family: $font-stack-euclid;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-color: $light-bg-gray;
}

.statusContainerDarkGrey {
    background-color: #CDCDCE;
    margin-left: 5px;
}


.contentBlock {
    height: 65%;
    width: 100%;
    display: flex;
}

.ownerDetails {
    width: 65%;
    overflow: hidden;
}

.accountDetails {
    width: 35%;
    border-left: 1px solid $light-blue;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lastPercentContainer {
    margin-top: 5px;
}

.dataRow {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.radio {
    margin-right: 5px;
}

.active {
    border: 1.5px solid $accent-blue;
    overflow: hidden;
}

.addressBlock {
    height: 30px;
}

.addressConfidence {
    margin-top: 4px
}

.backupContainer {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
}

.availableBalanceRow {
    display: flex;
    justify-content: space-between;
    overflow: visible;
}

.availableBalanceLabel {
    font-size: 10px;
    color: $dark-grey-text;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.availableBalance {
    font-size: 10px;
    font-weight: bold;
    color: $dark-grey-text;
    overflow: visible;
}

.percentage {
    display: flex;
    width: 80px;
}